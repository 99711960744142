import { type TenantConfiguration } from "@tamarack/sdk";
import { responseError } from "@tamarack/shared/errors";
import { ApiTenantConfigurationLoader } from "../../routes/api.tenant-configuration/route";
import { getTenantConfigurationUrl } from "../../routes/api.tenant-configuration/utils";
import { remixEndpointFetcher } from "../../utils/remixEndpointFetcher";
import AppStore from "./AppStore";

const TENANT_CONFIGURATION_PREFIX = "VITE_TENANT_CONFIGURATION_";

const tenantConfigurationStore = new AppStore<TenantConfiguration>(
  "tenantConfiguration",
  async () => {
    let tenantConfigurationOverride: Partial<TenantConfiguration> = {};
    for (const [key, value] of Object.entries(import.meta.env)) {
      if (key.startsWith(TENANT_CONFIGURATION_PREFIX)) {
        const tenantConfigurationKey = key.replace(
          TENANT_CONFIGURATION_PREFIX,
          ""
        ) as keyof TenantConfiguration;
        tenantConfigurationOverride[tenantConfigurationKey] = value;
      }
    }

    try {
      const data = await remixEndpointFetcher<ApiTenantConfigurationLoader>(
        getTenantConfigurationUrl()
      );

      return {
        ...data?.tenantConfiguration,
        ...tenantConfigurationOverride,
      } as TenantConfiguration;
    } catch (e) {
      responseError("Error loading tenant configuration", e);

      return undefined;
    }
  }
);

export default tenantConfigurationStore;
